// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

$.fn.isOnScreen = function(){
    
    var win = $(window);
    
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    
    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();
    
    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
};

$('div.alert').not('.alert-important').delay(2000).slideUp(300);

var theToggle = document.getElementById('toggle');

//based on Todd Motto functions
//http://toddmotto.com/labs/reusable-js/

//hasClass
function hasClass(elem, className) {
	return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
}
//addClass
function addClass(elem, className) {
 if (!hasClass(elem, className)) {
 	elem.className += ' ' + className;
 }
}
//removeClass
function removeClass(elem, className) {
	var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
	if (hasClass(elem, className)) {
     while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
         newClass = newClass.replace(' ' + className + ' ', ' ');
     }
     elem.className = newClass.replace(/^\s+|\s+$/g, '');
 }
}
//toggleClass
function toggleClass(elem, className) {
	var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, " " ) + ' ';
 if (hasClass(elem, className)) {
     while (newClass.indexOf(" " + className + " ") >= 0 ) {
         newClass = newClass.replace( " " + className + " " , " " );
     }
     elem.className = newClass.replace(/^\s+|\s+$/g, '');
 } else {
     elem.className += ' ' + className;
 }
}

theToggle.onclick = function() {
	toggleClass(this, 'on');
	$('.navbar-default').toggleClass( "open" );
	return false;
}
$('.menu-toggle').on('click', function(e) {
	theToggle.click()
});
var logo = document.getElementById('logo');
logo.onclick = function(e) {
	e.preventDefault();

	$('html,body').animate({
        scrollTop: 0
    }, 700);
	if($('#toggle').hasClass("on")){
		theToggle.click()
	}
}
var aboutlink = document.getElementById('aboutlink');
aboutlink.onclick = function(e) {
	e.preventDefault();

	$('html,body').animate({
        scrollTop: 0
    }, 700);
	if($('#toggle').hasClass("on")){
		theToggle.click()
	}
}


if ($(window).width() > 768){
	$('#navigation').affix({offset: {top: 430} });
} else {
	$('#navigation').affix({offset: {top: 450} });
}



var elementPosition = $('.services-nav').offset();
var servicesMenuFn = debounce(function () {
	if ($(window).width() > 768){
		if ($(window).scrollTop() > 430) {
			$('#header').addClass('header-visible');
		} else {
			$('#header').removeClass('header-visible');
		}
		if ($(window).scrollTop() > elementPosition.top - 70) {
			if ($('#services').isOnScreen()) {
		        $('.services-nav').addClass('services-nav__fixed');
		        $('#services').addClass('services__padding');
		        $('body').addClass('body-padding');
			} else {
		        $('.services-nav').removeClass('services-nav__fixed');
		        $('#services').removeClass('services__padding');
		        $('body').removeClass('body-padding');
		    }
	    } else {
	        $('.services-nav').removeClass('services-nav__fixed');
	        $('#services').removeClass('services__padding');
	        $('body').removeClass('body-padding');
	    }

	} else {
		if ($(window).scrollTop() > 450) {
			$('#header').addClass('header-visible');
		} else {
			$('#header').removeClass('header-visible');
		}
		if ($(window).scrollTop() > elementPosition.top - 50) {
			if ($('#services').isOnScreen()) {
		        $('.services-nav').addClass('services-nav__fixed');
		        $('#services').addClass('services__padding');
		        $('body').addClass('body-padding');
			} else {
		        $('.services-nav').removeClass('services-nav__fixed');
		        $('#services').removeClass('services__padding');
		        $('body').removeClass('body-padding');
		    }
	    } else {
	    	$('.services-nav').removeClass('services-nav__fixed');
	    	$('#services').removeClass('services__padding');
	        $('body').removeClass('body-padding');
	    }
	}
    
}, 5);
$(window).scroll(servicesMenuFn);

$('#serviceslink').click(function(){
    $('html, body').animate({
        scrollTop: $( $(this).attr('href') ).offset().top
    }, 500);
    return false;
});
$('#projectslink').click(function(){
    $('html, body').animate({
        scrollTop: $( $(this).attr('href') ).offset().top
    }, 500);
    return false;
});

$('.projects__slider__slide__thumb__container__thumb').on('click', function(e) {
	$('html, body').animate({
        scrollTop: $('#projects_anchor').offset().top
    }, 500);
});

$('#app-navbar-collapse').onePageNav();
$(window).scroll(function() {
    if ($(this).scrollTop() < 500) {
    	$('#app-navbar-collapse ul li').removeClass("current");
    	return;
    }
});

$('.carousel').carousel({
	interval: 7000,
	pause: false
})



if ($(window).width() > 768){
	smoothScroll.init({
		offset: 200
	});
} else {
	smoothScroll.init({
		offset: 160
	});
}

$('.slider-for').slick({
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  arrows: false,
	  fade: true,
	  asNavFor: '.slider-nav'
	});
$('.slider-nav').slick({
	centerMode: true,
	centerPadding: '60px',
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: false,
	dots: true,
	asNavFor: '.slider-for',
	focusOnSelect: true,
	responsive: [
	     	    {
	     	      breakpoint: 768,
	     	      settings: {
	     	        arrows: false,
	     	        dots: true,
	     	        centerMode: true,
	     	        centerPadding: '40px',
	     	        slidesToShow: 1
	     	      }
	     	    }
	     	  ]
});
